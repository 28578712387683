import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo';
import HeroComponent from '../components/sections/HeroComponent/HeroComponent';
import SectionWithVines from '../components/sections/SectionWithVines/SectionWithVines';
import SectionGrid from '../components/sections/SectionGrid/SectionGrid';
import SectionContent from '../components/sections/SectionContent/SectionContent';
import SectionDecorative from '../components/sections/SectionDecorative/SectionDecorative';

const Home = ({
    pageContext,
    location,
    data: {
        contentfulStlPageHome: {
            slug,
            seoTitle,
            seoDescription,
            pageTitle,
            sections,
            decorations
        }
    }
}) => {
    return (
        <div>
            <Seo
                title={seoTitle}
                description={seoDescription?.seoDescription}
                slug={slug}
                home={true}
            />
            <div className='content'>
                {
                    sections.map((section: any, index: number) => {

                        switch (section.__typename) {
                            case "ContentfulComponentBackgroundHero":
                                return (
                                    <HeroComponent data={section} key={index} />
                                )
                            case "ContentfulSectionWithVines":
                                return (
                                    <SectionWithVines data={section} key={index} />
                                )
                            case "ContentfulSectionGrid":
                                return (
                                    <SectionGrid data={section} key={index} />
                                )
                            case "ContentfulSectionContent":
                                return (
                                    <SectionContent data={section} key={index} />
                                )
                            // case "ContentfulStlSectionDecorativeItems":
                            //     return (
                            //         // <></>
                            //         <SectionDecorative data={section} key={index} />
                            //     )
                        }
                    })
                }
            {
                <SectionDecorative data={decorations} />
            }
            </div>
        </div>
    )
}

export const HomeQuery = graphql`
    query HomeQuery( $id: String! ){
        contentfulStlPageHome(id: {eq: $id}){
            slug
            seoTitle
            seoDescription {
                seoDescription
            }
            sections {
                __typename
                ...componentBackgroundHero
                ...sectionWithVines
                ...sectionGrid
                ...sectionDecorativeItems
                ...sectionContent
            }
            decorations {
                ...sectionDecorativeItems
            }
        }        
    }
            `
export default Home